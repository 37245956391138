<template>
  <div>
    <v-container>
      <v-row>
        <v-col
          cols="12"
          :md="rotationIsRandom ? 9 : 12"
          :lg="rotationIsRandom ? 10 : 12"
          class="pa-0 pr-md-2"
        >
          <ad-url-field
            :selenium-id="seleniumDynamicField(seleniumIds.SELENIUM_TEST_AD_FORM_CREATIVE_URL, creativeIndex)"
            :url="creative.url"
            :placeholder="$t('main.ad_form.url_placeholder')"
            :creative-index="creativeIndex"
            @input="$emit('update-creative', { value: $event, name: 'url' })"
            @focus="removeError(`creatives.${creativeIndex}.url`)"
          />
        </v-col>
        <v-col v-if="rotationIsRandom" cols="12" md="3" lg="2" class="pa-0">
          <creative-priority
            :creative-index="creativeIndex"
            :creative="creative"
            @input="$emit('update-creative', { value: $event, name: 'priority' })"
            @focus="removeError(`creatives.${creativeIndex}.priority`)"
          />
        </v-col>
      </v-row>
    </v-container>

    <c-text-field
      :id="seleniumDynamicField(seleniumIds.SELENIUM_TEST_AD_FORM_CREATIVE_STARTING_EVENT_ENDPOINT, creativeIndex)"
      :value="creative.startingEventEndpoint"
      :error-messages="errors[`creatives.${creativeIndex}.custom_starting_event_endpoint`]"
      hide-details="auto"
      class="mb-4"
      :label="$t('main.ad_form.starting_event_endpoint')"
      :placeholder="$t('main.ad_form.endpoint_placeholder')"
      label-bold
      @focus="removeError(`creatives.${creativeIndex}.custom_starting_event_endpoint`)"
      @input="$emit('update-creative', { value: $event, name: 'startingEventEndpoint' })"
    />

    <ad-formats-url-tokens
      v-if="creativeTokensLinks.length"
      :value="creative.startingEventEndpoint"
      :tokens="creativeTokensLinks"
      class="mb-4"
      @input="$emit('update-creative', { value: $event, name: 'startingEventEndpoint' })"
    />

    <div>
      <v-row>
        <v-col cols="12" sm="8">
          <span class="subtitle-2 black--text ma-0 pb-0 px-0">
            Image
          </span>

          <file-upload
            :id="seleniumDynamicField(seleniumIds.SELENIUM_TEST_AD_FORM_CREATIVE_IMAGE_FILEPOND, creativeIndex)"
            ref="fileUpload"
            :value="imageId"
            store-type="media"
            class="mt-2"
            :max-size="maxImageSize"
            image-crop-aspect-ratio="1"
            :label="$t('main.ad_form.img_load', { maxImageSize })"
            types="image/jpeg, image/png, image/gif"
            :error-messages="errors[`creatives.${creativeIndex}.image`]"
            @update="removeError(`creatives.${creativeIndex}.image`)"
            @input="$emit('update-creative', { value: $event, name: 'image' })"
          />
        </v-col>
        <v-col cols="12" sm="4">
          <span class="subtitle-2 black--text ma-0 pb-0 px-0">
            Icon
          </span>
          <file-upload
            :id="seleniumIds.SELENIUM_TEST_AD_FORM_CREATIVE_ICON_FILEPOND"
            ref="fileUpload-1"
            :value="iconId"
            image-crop-aspect-ratio="1"
            :label="$t('main.ad_form.icon_load', { extensions: 'JPG, PNG, GIF', maxImageSize })"
            types="image/jpeg, image/png, image/gif"
            class="mt-2"
            :max-size="maxImageSize"
            store-type="media"
            crop-size="1x1"
            :error-messages="
              errors[`creatives.${creativeIndex}.icon`] || errors[`creatives.icon`]
            "
            @update="removeError('creatives.icon')"
            @input="$emit('update-creative', { value: $event, name: 'icon' })"
          />
        </v-col>
      </v-row>
    </div>

    <tg-settings
      :rewarded-post-params="rewardedPostParams"
      :creative-index="creativeIndex"
      @input="$emit('update-creative', $event)"
    />
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import CTextField from '@clickadilla/components/ui/CTextField.vue'
  import seleniumIds from '@clickadilla/components/constants/selenium-ids.js'
  import seleniumDynamicField from '@clickadilla/components/utils/selenium-dynamic-field.js'
  import FileUpload from '@/components/FileUpload.vue'
  import TgSettings from '@/components/ads/InstreamForm/TgSettings.vue'
  import AdFormatsUrlTokens from '@/components/ads/AdFormatsUrlTokens/Index.vue'
  import AdUrlField from '@/components/ads/AdUrlField.vue'
  import CreativePriority from '@/components/ads/CreativePriority.vue'

  export default {
    name: 'InstreamTgBanner',
    components: {
      FileUpload,
      TgSettings,
      AdFormatsUrlTokens,
      CTextField,
      AdUrlField,
      CreativePriority
    },
    props: {
      creative: {
        type: Object,
        required: true
      },
      creativeIndex: {
        type: Number,
        required: true
      }
    },
    data() {
      return {
        seleniumIds
      }
    },
    computed: {
      ...mapState('adForm', ['errors']),
      ...mapGetters('settings', ['maxImageSize']),
      ...mapGetters('adForm', ['rotationIsRandom', 'creativeTokensLinks']),
      rewardedPostParams() {
        return this.creative.rewardedPostParams
      },
      iconId() {
        return this.creative.icon ? this.creative.icon.id : null
      },
      imageId() {
        return this.creative.image ? this.creative.image.id : null
      }
    },
    methods: {
      ...mapActions('adForm', ['removeError']),
      seleniumDynamicField
    }
  }
</script>
