<template>
  <expansion-panel-creative
    :title="$t('main.ad_form.creative')"
    :removable="removable"
    :creative="creative"
    :creative-index="creativeIndex"
  >
    <template #content>
      <v-card-text class="pa-6 mt-0">
        <component
          :is="componentNameByType"
          :creative="creative"
          :creative-index="creativeIndex"
          @update-creative="setCreative($event)"
        />

        <instream-preview-card ref="preview" :creative-for-preview="creativeForPreview" />
      </v-card-text>

      <v-card-actions v-if="isFinalElement && !getIsEditing" class="pa-6 pt-0">
        <c-btn
          :id="seleniumIds.SELENIUM_TEST_AD_FORM_CREATIVE_ADD"
          color="primary"
          outlined
          large
          :block="isMobile"
          :label="$t('main.ad_form.add')"
          :icon-props="{
            icon: '$plus',
            size: '14'
          }"
          @click="addCreative()"
        />
      </v-card-actions>
    </template>
  </expansion-panel-creative>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import { adTypes } from '@clickadilla/components/constants/ad-types.js'
  import seleniumIds from '@clickadilla/components/constants/selenium-ids.js'
  import InStreamVideo from '@/components/ads/InstreamForm/InstreamVideo.vue'
  import InstreamPreviewCard from '@/components/ads/InstreamForm/InstreamPreviewCard.vue'
  import InstreamVast from '@/components/ads/InstreamForm/InstreamVast.vue'
  import InstreamMotionBanner from '@/components/ads/InstreamForm/InstreamMotionBanner.vue'
  import InstreamTgBanner from '@/components/ads/InstreamForm/InstreamTgBanner.vue'
  import ExpansionPanelCreative from '@/components/ads/CreativeLayout/ExpansionPanelCreative.vue'

  export default {
    name: 'InStreamCreative',
    components: {
      InStreamVideo,
      InstreamVast,
      InstreamMotionBanner,
      ExpansionPanelCreative,
      InstreamPreviewCard,
      CBtn
    },
    props: {
      creative: {
        type: Object,
        required: true
      },
      creativeIndex: {
        type: Number,
        required: true
      },
      removable: Boolean
    },
    data() {
      return {
        seleniumIds,
        errorsPreview: null
      }
    },
    computed: {
      ...mapState('adForm', ['type', 'creatives', 'skipTime']),
      ...mapGetters('adForm', ['getIsEditing']),
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      },
      creativeForPreview() {
        return {
          ...this.creative,
          type: this.type,
          skipTime: this.skipTime
        }
      },
      componentNameByType() {
        return {
          [adTypes.WRAPPER_WITH_VPAID]: InstreamVast,
          [adTypes.WRAPPER_WITH_VIDEO]: InstreamVast,
          [adTypes.VIDEO]: InStreamVideo,
          [adTypes.MOTION_BANNER]: InstreamMotionBanner,
          [adTypes.REWARDED_POST]: InstreamTgBanner
        }[this.type]
      },
      isFinalElement() {
        return this.creativeIndex === this.creatives.length - 1
      }
    },
    watch: {
      type() {
        this.$refs.preview.previewIsUpdateHandler()
      }
    },
    methods: {
      ...mapActions('adForm', ['addCreative']),
      setCreative({ value, name }) {
        const creativeCopy = JSON.parse(JSON.stringify(this.creative))
        creativeCopy[name] = value
        this.$emit('update-creative', creativeCopy)
      }
    }
  }
</script>
